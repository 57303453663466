// queryparameter,追加,削除をstateに反映する
//  query parameter: action.payload[target]  配列
//  追加: action.payload.name, action.payload.value
//  削除: action.payload.name のみ
export const mergeState = (state, action, target) => {
  let exist = false
  const result = []
  if (action.payload[target] ){  // query parameter
    for (const record of action.payload[target]){
      result.push (record)
    }
  }else if (action.payload.value ){  // 追加
    if (state[target]){
      for (const name of state[target]){
        if (name == action.payload.name){
          exist = true
        }
        result.push (name)
      }
      if (!exist){
        result.push (action.payload.name)
      }
    }else{
      result.push(action.payload.name)
    }
  }else{  // 削除
    if (state[target]){
      for (const name of state[target]){
        if (name != action.payload.name){
          result.push (name)
        }
      }
    }
  }
  return result
}
