import React, {Component} from 'react'
import SystemLabelMasterCollection from 'collections/system_label_master_collection'

class SelectRunningDistance extends Component {

  handleChange = (e) => {
    this.props.actions.changeSelectRunningDistanceFinish({
      selectRunningDistanceFinish: e.target.value,
    })
  }

  render() {
    const {  payload } = this.props.systemLabelMastersApi
    if (!payload) {return null}
    const systemLabelMasterCollection = new SystemLabelMasterCollection(payload)
    return (
      <li className='topSearchForm__item'>
        <h3 className='topSearchForm__title'>走行距離</h3>
        <p className="selectWrap size-s">
          <select className="select"
            onChange={this.handleChange}
          >
            <option value="" key="all">上限なし</option>
            {
              systemLabelMasterCollection.getValueList('item','select_running_distance').map(i => (
                <option value={i.get('label_key')} key={i.get('label_key')}>{i.getValueI18n()}</option>
              ))
            }
          </select>
        </p>
        <p className="topSearchForm__text">以内</p>
      </li>
    )
  }
}
export default SelectRunningDistance
