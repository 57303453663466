export default function getSearchItem(payload) {
  let statusCode;
  return fetch(`/api/v1/site/items/${payload.item_id}`, {
    mode: 'same-origin',
    credentials: 'same-origin',
  })
    .then(res => {
      statusCode = res.status;
      if (res.ok) {
        return res.json()
      }  else {
        throw new Error(res.statusText)
      }
    })
    .then(json => ({statusCode: statusCode, body: json}))
    .catch(error => {throw new Error(error.message)})
}
