import {connect} from 'react-redux'
import {bindActionCreators } from 'redux'
import * as actions from 'actions'
import SelectNenshiki from 'components/site/index/select_nenshiki'

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
})

const mapStateToProps = state => ({
  systemLabelMastersApi: state.systemLabelMastersApi,
  selectNenshikiStart: state.searchFormCondition.selectNenshikiStart,
})

const SelectNenshikiContainer = connect(mapStateToProps, mapDispatchToProps)(SelectNenshiki)

export default SelectNenshikiContainer
