import React from 'react'
import SystemLabelMasterCollection from 'collections/system_label_master_collection'
import SelectMakerModal from 'components/site/select_maker_modal'
import FormSelectMaker from 'components/site/select_maker'
import {exOptionLabel} from 'components/site/select_maker'

class SelectMaker extends FormSelectMaker {
  /**
   * render
   */
  render () {
    const {  payload } = this.props.systemLabelMastersApi
    if (!payload) {return null}
    const systemLabelMasterCollection = new SystemLabelMasterCollection(payload)
    return (
      <li className='topSearchForm__item'>
        <h3 className='topSearchForm__title'>{TRUCKERS.LANG.ITEM.MAKER}</h3>
        <p
          className='selectWrap'
          onClick={this.handleOpenModal}
        >
          <span className='select'>
            {exOptionLabel(this.props.selectMaker, this.props.systemLabelMastersApi)}
          </span>
        </p>
        <SelectMakerModal
          handleCloseModal={this.handleCloseModal}
          handleInputChange={this.handleInputChange}
          selectMaker={this.props.selectMaker}
          systemLabelMasterCollection={systemLabelMasterCollection}
          appElement={document.getElementById('site_items_index_view_container')}
          state={this.state}
          {...this.props}
        />
      </li>
    )
  }
}

export default SelectMaker
