import React, {Component} from 'react'
import SystemLabelMasterCollection from 'collections/system_label_master_collection'

class SelectNenshiki extends Component {

  handleChange = (e) => {
    this.props.actions.changeSelectNenshikiStart({
      selectNenshikiStart: (e.target.value).split('nenshiki_').join(''),
    })
  }

  render() {
    const {  payload } = this.props.systemLabelMastersApi;
    if (!payload) {return null}
    const systemLabelMasterCollection = new SystemLabelMasterCollection(payload);

    return (
      <li className='topSearchForm__item'>
        <h3 className='topSearchForm__title'>年式</h3>
        <p className="selectWrap size-s">
          <select className="select"
            onChange={this.handleChange}
          >
            <option value="" key="all">下限なし</option>
            {
              systemLabelMasterCollection.getValueList('item','nenshiki').map(i => (
                <option key={i.get('label_key')} value={i.get('label_key')} >{i.getValueI18n()}</option>
              ))
            }
          </select>
        </p>
        <p className="topSearchForm__text">以降</p>
      </li>
    )
  }
}

export default SelectNenshiki
