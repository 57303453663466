import React, { Component } from 'react'
import SystemLabelMasterCollection from 'collections/system_label_master_collection'
import SelectStorageLocationModal from './select_storage_location_modal'

// define
const prefsData = {
  region_hokkaidou: ['pref_1'],
  region_touhoku: [
    'pref_2', 'pref_3', 'pref_4', 'pref_5', 'pref_6', 'pref_7',
  ],
  region_kantou: [
    'pref_8', 'pref_9', 'pref_10', 'pref_11', 'pref_12', 'pref_13', 'pref_14', 'pref_19', 'pref_20',
  ],
  region_hokuriku: [
    'pref_15', 'pref_16', 'pref_17', 'pref_18',
  ],
  region_toukai: [
    'pref_21', 'pref_22', 'pref_23',
  ],
  region_kinki: [
    'pref_24', 'pref_25', 'pref_26', 'pref_27', 'pref_28', 'pref_29', 'pref_30',
  ],
  region_chugoku: [
    'pref_31', 'pref_32', 'pref_33', 'pref_34', 'pref_35',
  ],
  region_shikoku: [
    'pref_36', 'pref_37', 'pref_38', 'pref_39',
  ],
  region_kyuusyuu_hokubu: [
    'pref_40', 'pref_41', 'pref_42', 'pref_43', 'pref_44',
  ],
  region_kyuusyuu_nanbu: [
    'pref_45', 'pref_46', 'pref_47',
  ],
}

class SelectStorageLocation extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false
    }
  }

  handleOpenModal = () => {
    this.setState({ showModal: true });
  }

  handleCloseModal = (e) => {
    this.setState({ showModal: false });
    e.preventDefault();
  }

  /**
   * チェックボックスON/OFFハンドラ
   */
  handleInputChange = (e) => {
    const value = e.target.checked
    const name = e.target.name
    this.props.actions.changeSelectStorageLocation({
      name: name,
      value: value,
    })
    this.includePrefControl(name, value)
    if (value == false) {
      this.upperRegionOff(name)
    }
  }

  /**
   * region変更時は配下のON/OFFを行う
   */
  includePrefControl = (name, value) => {
    if (name.indexOf('region_') >= 0) {
      for (let labelKey of prefsData[name]) {
        this.props.actions.changeSelectStorageLocation({ name: labelKey, value: value })
      }
    }
  }

  /**
   * pref OFF時は上位のregionもOFFする
   */
  upperRegionOff = (name) => {
    for (let region in prefsData) {
      if (prefsData[region].indexOf(name) >= 0) {
        this.props.actions.changeSelectStorageLocation({ name: region, value: false })
        return
      }
    }
  }

  /**
   * render
   */
  render() {
    const { payload } = this.props.systemLabelMastersApi
    if (!payload) {
      return null
    }
    const systemLabelMasterCollection = new SystemLabelMasterCollection(payload)
    const { selectStorageLocation } = this.props
    return (
      <div className='searchArea__item'>
        <h3 className='searchArea__title'>{TRUCKERS.LANG.ITEM.LIST_SEARCH.REGION}</h3>
        <p
          className='searchArea__item__inner selectWrap'
          onClick={this.handleOpenModal}
        >
          <span className='select'>
            {exOptionLabel(this.props.selectStorageLocation, this.props.systemLabelMastersApi)}
          </span>
        </p>
        <SelectStorageLocationModal
          handleCloseModal={this.handleCloseModal}
          handleInputChange={this.handleInputChange}
          selectStorageLocation={selectStorageLocation}
          systemLabelMasterCollection={systemLabelMasterCollection}
          state={this.state}
          prefsData={prefsData}
          {...this.props}
        />
      </div>
    )
  }
}

const exOptionLabel = (selectStorageLocation, systemLabelMastersApi) => {
  const { payload } = systemLabelMastersApi
  if (!payload) {
    return TRUCKERS.LANG.ITEM.LIST_SEARCH.SELECT_WITH_CLICK
  }
  const systemLabelMasterCollection = new SystemLabelMasterCollection(payload)
  if (!selectStorageLocation) {
    return TRUCKERS.LANG.ITEM.LIST_SEARCH.SELECT_WITH_CLICK
  }
  let result = []
  for (let labelKey of selectStorageLocation) {
    if (labelKey.indexOf('region_') == -1) {
      result.push(systemLabelMasterCollection.getValue('storage_location', 'todoufuken', labelKey))
    }
  }
  return (result.length > 0) ? result.join(',') : TRUCKERS.LANG.ITEM.LIST_SEARCH.SELECT_WITH_CLICK
}

export { prefsData }
export default SelectStorageLocation
