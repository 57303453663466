import { call, put } from 'redux-saga/effects'
import * as actions from 'actions'
import apiDeleteFavorites from 'api/delete_favorites'

export default function* deleteFavorites(action) {
  try{
    const {statusCode, body} = yield call(apiDeleteFavorites, action.payload)
    const meta = { statusCode: statusCode }
    yield put(actions.deleteFavoritesOK(body, meta))
  } catch (e) {
    const payload = e.message
    yield put(actions.deleteFavoritesNG(payload, null))
  }
}

