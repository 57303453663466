const ActionTypes = {
  // for systemLabelMasterApi
  GET_SYSTEM_LABEL_MASTERS: 'GET_SYSTEM_LABEL_MASTERS',
  GET_SYSTEM_LABEL_MASTERS_OK: 'GET_SYSTEM_LABEL_MASTERS_OK',
  GET_SYSTEM_LABEL_MASTERS_NG: 'GET_SYSTEM_LABEL_MASTERS_NG',

  // for siteItemsApi
  GET_SEARCH_ITEMS: 'GET_SEARCH_ITEMS',
  GET_SEARCH_ITEMS_OK: 'GET_SEARCH_ITEMS_OK',
  GET_SEARCH_ITEMS_NG: 'GET_SEARCH_ITEMS_NG',

  GET_SEARCH_ITEM: 'GET_SEARCH_ITEM',
  GET_SEARCH_ITEM_OK: 'GET_SEARCH_ITEM_OK',

  // for favositesApi
  POST_FAVORITES: 'POST_FAVORITES',
  POST_FAVORITES_OK: 'POST_FAVORITES_OK',
  POST_FAVORITES_NG: 'POST_FAVORITES_NG',
  DELETE_FAVORITES: 'DELETE_FAVORITES',
  DELETE_FAVORITES_OK: 'DELETE_FAVORITES_OK',
  DELETE_FAVORITES_NG: 'DELETE_FAVORITES_NG',

  // for searchFormCondition
  CHANGE_SELECT_BODY_KEIJOU: 'CHANGE_SELECT_BODY_KEIJOU',
  CHANGE_SELECT_MAKER: 'CHANGE_SELECT_MAKER',
  CHANGE_SELECT_BODY_SIZE: 'CHANGE_SELECT_BODY_SIZE',
  CHANGE_SELECT_TRANSMISSION: 'CHANGE_SELECT_TRANSMISSION',
  CHANGE_SELECT_BED: 'CHANGE_SELECT_BED',
  CHANGE_SELECT_KUDOUHOUSHIKI: 'CHANGE_SELECT_KUDOUHOUSHIKI',
  CHANGE_SELECT_FUEL_TYPE: 'CHANGE_SELECT_FUEL_TYPE',
  CHANGE_SELECT_POWER_GATE: 'CHANGE_SELECT_POWER_GATE',
  CHANGE_SELECT_NENSHIKI_START: 'CHANGE_SELECT_NENSHIKI_START',
  CHANGE_SELECT_NENSHIKI_FINISH: 'CHANGE_SELECT_NENSHIKI_FINISH',
  CHANGE_SELECT_RUNNING_DISTANCE_START: 'CHANGE_SELECT_RUNNING_DISTANCE_START',
  CHANGE_SELECT_RUNNING_DISTANCE_FINISH: 'CHANGE_SELECT_RUNNING_DISTANCE_FINISH',
  CHANGE_SELECT_PRICE_START: 'CHANGE_SELECT_PRICE_START',
  CHANGE_SELECT_PRICE_FINISH: 'CHANGE_SELECT_PRICE_FINISH',
  CHANGE_SELECT_STORAGE_LOCATION: 'CHANGE_SELECT_STORAGE_LOCATION',
  CHANGE_SELECT_SELLER_KIND: 'CHANGE_SELECT_SELLER_KIND',
  CHANGE_SELECT_FREE_WORD: 'CHANGE_SELECT_FREE_WORD',
  CHANGE_SELECT_ENGINE_BARIKI: 'CHANGE_SELECT_ENGINE_BARIKI',
  CHANGE_SELECT_SOLD_OUT: 'CHANGE_SELECT_SOLD_OUT',
  CHANGE_SELECT_EXHIBIT_TYPE: 'CHANGE_SELECT_EXHIBIT_TYPE',
  CHANGE_SELECT_BRAND_KEY: 'CHANGE_SELECT_BRAND_KEY',
  CHANGE_SELECT_SAIDAISEKISAIRYOU_START: 'CHANGE_SELECT_SAIDAISEKISAIRYOU_START',
  CHANGE_SELECT_SAIDAISEKISAIRYOU_FINISH: 'CHANGE_SELECT_SAIDAISEKISAIRYOU_FINISH',
  CHANGE_SELECT_SYARYOUSOUJUURYOU_START: 'CHANGE_SELECT_SYARYOUSOUJUURYOU_START',
  CHANGE_SELECT_SYARYOUSOUJUURYOU_FINISH: 'CHANGE_SELECT_SYARYOUSOUJUURYOU_FINISH',
  CHANGE_SELECT_CABIN_WIDTH: 'CHANGE_SELECT_CABIN_WIDTH',
  CHANGE_SELECT_SUSPENSION: 'CHANGE_SELECT_SUSPENSION',
  CHANGE_SELECT_NISHITSUNAISUN_LENGTH_START: 'CHANGE_SELECT_NISHITSUNAISUN_LENGTH_START',
  CHANGE_SELECT_NISHITSUNAISUN_LENGTH_FINISH: 'CHANGE_SELECT_NISHITSUNAISUN_LENGTH_FINISH',
  CHANGE_SELECT_NISHITSUNAISUN_WIDTH_START: 'CHANGE_SELECT_NISHITSUNAISUN_WIDTH_START',
  CHANGE_SELECT_NISHITSUNAISUN_WIDTH_FINISH: 'CHANGE_SELECT_NISHITSUNAISUN_WIDTH_FINISH',
  CHANGE_SELECT_NISHITSUNAISUN_HEIGHT_START: 'CHANGE_SELECT_NISHITSUNAISUN_HEIGHT_START',
  CHANGE_SELECT_NISHITSUNAISUN_HEIGHT_FINISH: 'CHANGE_SELECT_NISHITSUNAISUN_HEIGHT_FINISH',
  CHANGE_SELECT_SYARYOUGAISUN_LENGTH_START: 'CHANGE_SELECT_SYARYOUGAISUN_LENGTH_START',
  CHANGE_SELECT_SYARYOUGAISUN_LENGTH_FINISH: 'CHANGE_SELECT_SYARYOUGAISUN_LENGTH_FINISH',
  CHANGE_SELECT_SYARYOUGAISUN_WIDTH_START: 'CHANGE_SELECT_SYARYOUGAISUN_WIDTH_START',
  CHANGE_SELECT_SYARYOUGAISUN_WIDTH_FINISH: 'CHANGE_SELECT_SYARYOUGAISUN_WIDTH_FINISH',
  CHANGE_SELECT_SYARYOUGAISUN_HEIGHT_START: 'CHANGE_SELECT_SYARYOUGAISUN_HEIGHT_START',
  CHANGE_SELECT_SYARYOUGAISUN_HEIGHT_FINISH: 'CHANGE_SELECT_SYARYOUGAISUN_HEIGHT_FINISH',

  CLEAR_CONDITION: 'CLEAR_CONDITION',
  CHANGE_PAGE: 'CHANGE_PAGE',
  CHANGE_SORT_RULE: 'CHANGE_SORT_RULE',

  EXEC_GET_SEARCH_ITEMS: 'EXEC_GET_SEARTH_ITEMS',
  EXEC_GET_SEARCH_ITEMS_COMPLETE: 'EXEC_GET_SEARTH_ITEMS_COMPLETE',

  SETUP_SEARCH_FORM_CONDITION: 'SETUP_SEARCH_FORM_CONDITION',
}
export default ActionTypes
