import logger from 'common/logger'
export default function deleteFavorites(payload) {
  let statusCode
  logger("Api deleteFavorites is called")
  return fetch(`/api/v1/companies/${payload.company_id}/favorites/${payload.favorite_id}`, {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    mode: 'same-origin',
    credentials: 'same-origin',
  })
    .then(res => {
      logger("Api deleteFavorites is finished")
      logger(res)
      statusCode = res.status
      if(res.ok) {
        return res.json()
      } else {
        throw new Error(res.statusText)
      }
    })
    .then(json => ({statusCode: statusCode, body: json}))
    .catch(error => {throw new Error(error.message)})
}
