import { call, put } from 'redux-saga/effects'
import * as actions from 'actions'
import apiPostFavorites from 'api/post_favorites'

export default function* postFavorites(action) {
  try{
    const {statusCode, body} = yield call(apiPostFavorites, action.payload)
    const meta = { statusCode: statusCode }
    yield put(actions.postFavoritesOK(body, meta))
  } catch (e) {
    const payload = e.message
    yield put(actions.postFavoritesNG(payload, null))
  }
}

