import React, {Component} from 'react'
import SelectBodyKeijouContainer from 'containers/site/index/select_body_keijou_container'
import SelectMakerContainer from 'containers/site/index/select_maker_container'
import SelectBodySizeContainer from 'containers/site/index/select_body_size_container'
import SelectRunningDistanceContainer from 'containers/site/index/select_running_distance_container'
import SelectNenshikiContainer from 'containers/site/index/select_nenshiki_container'
import createSearchFormCondition from 'components/site/search_form_condition'
import execSearchSubmit from 'components/site/exec_search_submit'
import I18n from "../../../packs/i18n/i18n-js.js"


const t = I18n.t

const appElement = () => (
  document.getElementById('site_index_view_container')
)

class Index extends Component {

  componentDidMount() {
    this.props.actions.getSystemLabelMasters()
  }

  handleSubmit = (e) => {
    createSearchFormCondition(this.props.searchFormCondition, {initPage: true})
    execSearchSubmit(this.props.searchFormCondition, this.props.history, true)
    e.preventDefault()
  }

  render(){
    return(
      <div className="c-flex">
        <ul className="topSearchForm">
          <SelectBodyKeijouContainer
            appElement={appElement()}
          />
          <SelectMakerContainer
            appElement={appElement()}
          />
          <SelectBodySizeContainer />
          <SelectRunningDistanceContainer />
          <SelectNenshikiContainer />
        </ul>
        <ul className="topSearch__button">
          <li className="topSearch__button__item" onClick={this.handleSubmit}>
            <a href="#" className="moreBtn size-s primary">
              <i className="material-icons">search</i>{t("button.search")}
            </a>
          </li>
        </ul>
      </div>
    )
  }
}
export default Index
