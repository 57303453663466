import BaseCollection from './base_collection'
import SystemLabelMasterModel from './../models/system_label_master_model'
export default class SystemLabelMasterCollection extends BaseCollection {

  models = []

  // params: reducer
  constructor(records) {
    super()
    for (let record of records){
      this.models.push(new SystemLabelMasterModel(record))
    }
  }

  /**
   * tableNameとcolumnNameからsystemLabelMasterModelの配列を得る
   */
  getValueList(tableName, columnName) {
    let resultList = []
    for (let systemLabelMaster of this.models){
      if (systemLabelMaster.get('table_name') == tableName
          && systemLabelMaster.get('column_name') == columnName) {
        resultList.push(systemLabelMaster)
      }
    }
    return resultList
  }

  /**
   * getValue
   */
  getValue(tableName, columnName, labelKey) {
    if (!labelKey) {
      return '-'
    }

    for (let systemLabelMasterModel of this.models){
      if (systemLabelMasterModel.get('table_name') == tableName
        && systemLabelMasterModel.get('column_name') == columnName
        && systemLabelMasterModel.get('label_key') == labelKey){
        return systemLabelMasterModel.getValueI18n()
      }
    }
    return '-'
  }

  /**
   * getSystemLabelMasterModel
   */
  getSystemLabelMasterModel(tableName, columnName, labelKey) {
    if (!labelKey) {
      return null
    }

    for (let systemLabelMasterModel of this.models){
      if (systemLabelMasterModel.get('table_name') == tableName
        && systemLabelMasterModel.get('column_name') == columnName
        && systemLabelMasterModel.get('label_key') == labelKey){
        return systemLabelMasterModel
      }
    }
    return null
  }
}
