const createSearchFormCondition = (condition, options={}) => {
  let result = Object.keys(condition).reduce((payload, key) => {
    if (key == 'execGetSearchItems'){  // execGetSearchItems は除外する
      return payload
    }

    const value = condition[key];

    if (typeof value == "object" && Object.keys(value).length === 0) {
      return payload;
    }
    if (value === null || value === undefined || value === '') {
      return payload;
    }

    payload[key] = value;
    return payload
  },{})

  // ページを初期化する
  if (options.initPage){
    result['page'] = 1
  }
  return result
}
export default createSearchFormCondition
