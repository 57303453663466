import React from 'react'
import ReactModal from 'react-modal'
import { CloseButton, SubmitButton, CloseButtonBottom } from 'components/common/modal_parts'

const modalStyle = {
  content: {
    width: 'calc(100% - 20vw)',
    maxHeight: '90%',
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    border: "none",
    padding: "50px",
    transform: "translate(-50%, -50%)"
  },
  overlay: {
    zIndex: '150',
    backgroundColor: "rgba(19, 165, 112, 0.8)"
  },
}

/**
 * チェックボックスを描画する
 */
const InputUnit = (props) => {
  const { labelKey, checked, value, className = '', handleInputChange } = props
  return (
    <li key={labelKey} className={className}>
      <input
        name={labelKey}
        id={labelKey}
        type="checkbox"
        checked={checked}
        onChange={handleInputChange}
        style={{ cursor: 'pointer' }}
      />
      <label
        key={labelKey}
        htmlFor={labelKey}
        className='pl-1'
        style={{ cursor: 'pointer' }}
      >
        {value}
      </label>
    </li>
  )
}

/**
 * 選択されているかチェックする
 */
const selectChecked = (selected, labelKey) => {
  if (selected && selected.indexOf(labelKey) >= 0) {
    return true
  }
  return false
}

/**
 * 指定されたmakerを描画する
 */
const Maker = (props) => {
  const { selectMaker, systemLabelMasterCollection, labelKey } = props
  const systemLabelMasterModel = systemLabelMasterCollection.getSystemLabelMasterModel('item', 'maker', labelKey)
  return (
    <InputUnit
      {...props}
      checked={selectChecked(selectMaker, labelKey)}
      value={systemLabelMasterModel.getValueI18n()}
      className='modalColumn__child__item size-m checkbox'
    />
  )
}

const SelectMakerModal = (props) => {
  const { showModal } = props.state
  const { handleCloseModal, appElement } = props
  return (
    <ReactModal
      isOpen={showModal}
      appElement={appElement}
      onRequestClose={handleCloseModal}
      style={modalStyle}
    >
      <h2 className="headline2 ReactModal__title">{TRUCKERS.LANG.ITEM.LIST_SEARCH.SELECT_MAKER}</h2>
      <ul className="modalColumn">
        <li className="modalColumn__item">
          <div className='modalColumn__title'>トラック</div>
          <ul className="modalColumn__child">
            <Maker {...props} labelKey='maker_hino'/>
            <Maker {...props} labelKey='maker_isuzu'/>
            <Maker {...props} labelKey='maker_fuso'/>
            <Maker {...props} labelKey='maker_ud'/>
            <Maker {...props} labelKey='maker_toyota'/>
            <Maker {...props} labelKey='maker_nissan'/>
            <Maker {...props} labelKey='maker_honda'/>
            <Maker {...props} labelKey='maker_matsuda'/>
            <Maker {...props} labelKey='maker_subaru'/>
            <Maker {...props} labelKey='maker_suzuki'/>
            <Maker {...props} labelKey='maker_daihatsu'/>
            <Maker {...props} labelKey='maker_benz'/>
            <Maker {...props} labelKey='maker_volvo'/>
            <Maker {...props} labelKey='maker_scania'/>
          </ul>
        </li>
        <li className="modalColumn__item">
          <div className='modalColumn__title'>トレーラー</div>
          <ul className="modalColumn__child">
            <Maker {...props} labelKey='maker_tokyu'/>
            <Maker {...props} labelKey='maker_touhousyaryou'/>
            <Maker {...props} labelKey='maker_hanamidai'/>
            <Maker {...props} labelKey='maker_trex'/>
            <Maker {...props} labelKey='maker_toremo'/>
            <Maker {...props} labelKey='maker_fullhalf'/>
            <Maker {...props} labelKey='maker_yusoki'/>
            <Maker {...props} labelKey='maker_dainichi'/>
            <Maker {...props} labelKey='maker_anchiko'/>
          </ul>
        </li>
        <li className="modalColumn__item">
          <div className='modalColumn__title'>その他</div>
          <ul className="modalColumn__child">
            <Maker {...props} labelKey='maker_other'/>
          </ul>
        </li>
      </ul>
      <SubmitButton handleClick={handleCloseModal}/>
      <CloseButton handleClick={handleCloseModal}/>
      <CloseButtonBottom handleClick={handleCloseModal}/>
    </ReactModal>
  )
}

export default SelectMakerModal
