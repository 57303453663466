import React from 'react'

export const CloseButton = ({handleClick}) => (
  <div className='ReactModal__close'>
    <button
      className='btn btn-primary'
      onClick={handleClick}
    >
      <i className='material-icons'>close</i>
    </button>
  </div>
)

export const CloseButtonBottom = ({handleClick}) => (
  <div className='ReactModal__close bottom'>
    <button
      className='btn btn-primary'
      onClick={handleClick}
    >
      <i className='material-icons'>close</i>
    </button>
  </div>
)

export const SubmitButton = ({handleClick}) => (
  <p className="modalButton">
    <a
      href="#"
      className="moreBtn secondary size-l"
      onClick={handleClick}
    >
      決定
    </a>
  </p>
)
