// actions
import ActionTypes from './action_types'


export const getSystemLabelMasters = (payload={}, meta={}) => ({
  type: ActionTypes.GET_SYSTEM_LABEL_MASTERS,
  payload,
  meta,
})

export const getSystemLabelMastersOK = (payload={}, meta={}) => ({
  type: ActionTypes.GET_SYSTEM_LABEL_MASTERS_OK,
  payload,
  meta,
})

export const getSystemLabelMastersNG = (payload={}, meta={}) => ({
  type: ActionTypes.GET_SYSTEM_LABEL_MASTERS_NG,
  payload,
  meta,
})

export const getSearchItems = (payload={}, meta={}) => ({
  type: ActionTypes.GET_SEARCH_ITEMS,
  payload,
  meta,
})

export const getSearchItemsOK = (payload={}, meta={}) => ({
  type: ActionTypes.GET_SEARCH_ITEMS_OK,
  payload,
  meta,
})

export const getSearchItemsNG = (payload={}, meta={}) => ({
  type: ActionTypes.GET_SEARCH_ITEMS_NG,
  payload,
  meta,
})

export const postFavorites = (payload={}, meta={}) => ({
  type: ActionTypes.POST_FAVORITES,
  payload,
  meta,
})

export const postFavoritesOK = (payload={}, meta={}) => ({
  type: ActionTypes.POST_FAVORITES_OK,
  payload,
  meta,
})

export const postFavoritesNG = (payload={}, meta={}) => ({
  type: ActionTypes.POST_FAVORITES_NG,
  payload,
  meta,
})

export const deleteFavorites = (payload={}, meta={}) => ({
  type: ActionTypes.DELETE_FAVORITES,
  payload,
  meta,
})

export const deleteFavoritesOK = (payload={}, meta={}) => ({
  type: ActionTypes.DELETE_FAVORITES_OK,
  payload,
  meta,
})

export const deleteFavoritesNG = (payload={}, meta={}) => ({
  type: ActionTypes.DELETE_FAVORITES_NG,
  payload,
  meta,
})

export const changeSelectBodyKeijou = (payload={}, meta={}) => ({
  type: ActionTypes.CHANGE_SELECT_BODY_KEIJOU,
  payload,
  meta,
})

export const changeSelectMaker = (payload={}, meta={}) => ({
  type: ActionTypes.CHANGE_SELECT_MAKER,
  payload,
  meta,
})

export const changeSelectBodySize = (payload={}, meta={}) => ({
  type: ActionTypes.CHANGE_SELECT_BODY_SIZE,
  payload,
  meta,
})

export const changeSelectTransmission = (payload={}, meta={}) => ({
  type: ActionTypes.CHANGE_SELECT_TRANSMISSION,
  payload,
  meta,
})

export const changeSelectBed = (payload={}, meta={}) => ({
  type: ActionTypes.CHANGE_SELECT_BED,
  payload,
  meta,
})

export const changeSelectKudouhoushiki = (payload={}, meta={}) => ({
  type: ActionTypes.CHANGE_SELECT_KUDOUHOUSHIKI,
  payload,
  meta,
})

export const changeSelectFuelType = (payload={}, meta={}) => ({
  type: ActionTypes.CHANGE_SELECT_FUEL_TYPE,
  payload,
  meta,
})

export const changeSelectPowerGate = (payload={}, meta={}) => ({
  type: ActionTypes.CHANGE_SELECT_POWER_GATE,
  payload,
  meta,
})

export const changeSelectNenshikiStart = (payload={}, meta={}) => ({
  type: ActionTypes.CHANGE_SELECT_NENSHIKI_START,
  payload,
  meta,
})

export const changeSelectNenshikiFinish = (payload={}, meta={}) => ({
  type: ActionTypes.CHANGE_SELECT_NENSHIKI_FINISH,
  payload,
  meta,
})

export const changeSelectRunningDistanceStart = (payload={}, meta={}) => ({
  type: ActionTypes.CHANGE_SELECT_RUNNING_DISTANCE_START,
  payload,
  meta,
})

export const changeSelectRunningDistanceFinish = (payload={}, meta={}) => ({
  type: ActionTypes.CHANGE_SELECT_RUNNING_DISTANCE_FINISH,
  payload,
  meta,
})

export const changeSelectPriceStart = (payload={}, meta={}) => ({
  type: ActionTypes.CHANGE_SELECT_PRICE_START,
  payload,
  meta,
})

export const changeSelectPriceFinish = (payload={}, meta={}) => ({
  type: ActionTypes.CHANGE_SELECT_PRICE_FINISH,
  payload,
  meta,
})

export const changeSelectStorageLocation = (payload={}, meta={}) => ({
  type: ActionTypes.CHANGE_SELECT_STORAGE_LOCATION,
  payload,
  meta,
})

export const changeSelectSellerKind = (payload={}, meta={}) => ({
  type: ActionTypes.CHANGE_SELECT_SELLER_KIND,
  payload,
  meta,
})

export const changeSelectFreeWord = (payload={}, meta={}) => ({
  type: ActionTypes.CHANGE_SELECT_FREE_WORD,
  payload,
  meta,
})

export const changeSelectEngineBariki = (payload={}, meta={}) => ({
  type: ActionTypes.CHANGE_SELECT_ENGINE_BARIKI,
  payload,
  meta,
})

export const changeSelectSoldOut = (payload={}, meta={}) => ({
  type: ActionTypes.CHANGE_SELECT_SOLD_OUT,
  payload,
  meta,
})

export const changeSelectBrandKey = (payload={}, meta={}) => ({
  type: ActionTypes.CHANGE_SELECT_BRAND_KEY,
  payload,
  meta,
})

export const changeSelectExhibitType = (payload={}, meta={}) => ({
  type: ActionTypes.CHANGE_SELECT_EXHIBIT_TYPE,
  payload,
  meta,
});

export const changeSelectSaidaisekisairyouStart = (payload={}, meta={}) => ({
  type: ActionTypes.CHANGE_SELECT_SAIDAISEKISAIRYOU_START,
  payload,
  meta,
});

export const changeSelectSaidaisekisairyouFinish = (payload={}, meta={}) => ({
  type: ActionTypes.CHANGE_SELECT_SAIDAISEKISAIRYOU_FINISH,
  payload,
  meta,
});

export const changeSelectSyaryousoujuuryouStart = (payload={}, meta={}) => ({
  type: ActionTypes.CHANGE_SELECT_SYARYOUSOUJUURYOU_START,
  payload,
  meta,
});

export const changeSelectSyaryousoujuuryouFinish = (payload={}, meta={}) => ({
  type: ActionTypes.CHANGE_SELECT_SYARYOUSOUJUURYOU_FINISH,
  payload,
  meta,
});

export const changeSelectCabinWidth = (payload={}, meta={}) => ({
  type: ActionTypes.CHANGE_SELECT_CABIN_WIDTH,
  payload,
  meta,
});

export const changeSelectSuspension = (payload={}, meta={}) => ({
  type: ActionTypes.CHANGE_SELECT_SUSPENSION,
  payload,
  meta,
});

export const changeSelectNishitsunaisunLengthStart = (payload={}, meta={}) => ({
  type: ActionTypes.CHANGE_SELECT_NISHITSUNAISUN_LENGTH_START,
  payload,
  meta,
})

export const changeSelectNishitsunaisunLengthFinish = (payload={}, meta={}) => ({
  type: ActionTypes.CHANGE_SELECT_NISHITSUNAISUN_LENGTH_FINISH,
  payload,
  meta,
})

export const changeSelectNishitsunaisunWidthStart = (payload={}, meta={}) => ({
  type: ActionTypes.CHANGE_SELECT_NISHITSUNAISUN_WIDTH_START,
  payload,
  meta,
})

export const changeSelectNishitsunaisunWidthFinish = (payload={}, meta={}) => ({
  type: ActionTypes.CHANGE_SELECT_NISHITSUNAISUN_WIDTH_FINISH,
  payload,
  meta,
})

export const changeSelectNishitsunaisunHeightStart = (payload={}, meta={}) => ({
  type: ActionTypes.CHANGE_SELECT_NISHITSUNAISUN_HEIGHT_START,
  payload,
  meta,
})

export const changeSelectNishitsunaisunHeightFinish = (payload={}, meta={}) => ({
  type: ActionTypes.CHANGE_SELECT_NISHITSUNAISUN_HEIGHT_FINISH,
  payload,
  meta,
})

export const changeSelectSyaryougaisunLengthStart = (payload={}, meta={}) => ({
  type: ActionTypes.CHANGE_SELECT_SYARYOUGAISUN_LENGTH_START,
  payload,
  meta,
})

export const changeSelectSyaryougaisunLengthFinish = (payload={}, meta={}) => ({
  type: ActionTypes.CHANGE_SELECT_SYARYOUGAISUN_LENGTH_FINISH,
  payload,
  meta,
})
export const changeSelectSyaryougaisunWidthStart = (payload={}, meta={}) => ({
  type: ActionTypes.CHANGE_SELECT_SYARYOUGAISUN_WIDTH_START,
  payload,
  meta,
})

export const changeSelectSyaryougaisunWidthFinish = (payload={}, meta={}) => ({
  type: ActionTypes.CHANGE_SELECT_SYARYOUGAISUN_WIDTH_FINISH,
  payload,
  meta,
})

export const changeSelectSyaryougaisunHeightStart = (payload={}, meta={}) => ({
  type: ActionTypes.CHANGE_SELECT_SYARYOUGAISUN_HEIGHT_START,
  payload,
  meta,
})

export const changeSelectSyaryougaisunHeightFinish = (payload={}, meta={}) => ({
  type: ActionTypes.CHANGE_SELECT_SYARYOUGAISUN_HEIGHT_FINISH,
  payload,
  meta,
})

export const clearCondition = (payload={}, meta={}) => ({
  type: ActionTypes.CLEAR_CONDITION,
  payload,
  meta,
})

export const changePage = (payload={}, meta={}) => ({
  type: ActionTypes.CHANGE_PAGE,
  payload,
  meta,
})

export const execGetSearchItems = (payload={}, meta={}) => ({
  type: ActionTypes.EXEC_GET_SEARCH_ITEMS,
  payload,
  meta,
})

export const execGetSearchItemsComplete = (payload={}, meta={}) => ({
  type: ActionTypes.EXEC_GET_SEARCH_ITEMS_COMPLETE,
  payload,
  meta,
})

export const setupSearchFormCondition = (payload={}, meta={}) => ({
  type: ActionTypes.SETUP_SEARCH_FORM_CONDITION,
  payload,
  meta,
})

export const changeSortRule = (payload={}, meta={}) => ({
  type: ActionTypes.CHANGE_SORT_RULE,
  payload,
  meta,
});

export const getSearchItem = (payload={}, meta={}) => ({
  type: ActionTypes.GET_SEARCH_ITEM,
  payload,
  meta,
});

export const getSearchItemOK = (payload={}, meta={}) => ({
  type: ActionTypes.GET_SEARCH_ITEM_OK,
  payload,
  meta,
});
