import React from 'react'
import SystemLabelMasterCollection from 'collections/system_label_master_collection'
import SelectBodyKeijouModal from 'components/site/select_body_keijou_modal'
import FormSelectBodyKeijou from 'components/site/select_body_keijou'
import {exOptionLabel, bodyKeijouData} from 'components/site/select_body_keijou'

class SelectBodyKeijou extends FormSelectBodyKeijou {
  /**
   * render
   */
  render () {
    const {  payload } = this.props.systemLabelMastersApi
    if (!payload) {return null}
    const systemLabelMasterCollection = new SystemLabelMasterCollection(payload)
    const {selectBodyKeijou, systemLabelMastersApi} = this.props
    return (
      <li className='topSearchForm__item'>
        <h3 className='topSearchForm__title'>{TRUCKERS.LANG.ITEM.BODY_KEIJOU}</h3>
        <p
          className='selectWrap'
          onClick={this.handleOpenModal}
        >
          <span className='select'>
            {exOptionLabel(selectBodyKeijou, systemLabelMastersApi)}
          </span>
        </p>
        <SelectBodyKeijouModal
          handleCloseModal={this.handleCloseModal}
          handleInputChange={this.handleInputChange}
          selectBodyKeijou={selectBodyKeijou}
          systemLabelMasterCollection={systemLabelMasterCollection}
          bodyKeijouData={bodyKeijouData}
          state={this.state}
          {...this.props}
        />
      </li>
    )
  }
}

export default SelectBodyKeijou
