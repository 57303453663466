import {connect} from 'react-redux'
import {bindActionCreators } from 'redux'
import * as actions from 'actions'
import SelectBodySize from 'components/site/index/select_body_size'

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
})

const mapStateToProps = state => ({
  systemLabelMastersApi: state.systemLabelMastersApi,
  selectBodySize: state.searchFormCondition.selectBodySize,
})

const SelectBodySizeContainer = connect(mapStateToProps, mapDispatchToProps)(SelectBodySize)

export default SelectBodySizeContainer
