import createSearchFormCondition from './search_form_condition'
import { queryStringfy } from 'common/util'

// search_form と item_list でsubmitを実施するため、ここで共通化する
const execSearchSubmit = (storeSearchFormCondition, history, transition) => {
  // 検索ボタン押下時はpageを初期化する
  const searchFormCondition = createSearchFormCondition(storeSearchFormCondition, {initPage: true})

  if (transition) {
    window.location.href = renderUrl(searchFormCondition)
  } else {
    // history.pushにより、locationを /site/items へ変更する。（items.js でlocation変更を検知し、api取得を行う）
    history.push(renderUrl(searchFormCondition))
  }
}

const isMakerNormalizeUrl = (conditions) => {
  return isValidDataSize(conditions, "selectMaker", 1) && isEmptyOtherConditions(conditions, "selectMaker")
}

const isBodyTypeNormalizeUrl = (conditions) => {
  return isValidDataSize(conditions, "selectBodyKeijou", 1) && isEmptyOtherConditions(conditions, "selectBodyKeijou")
}

const isBrandNormalizeUrl = (conditions) => {
  return !!conditions["selectBrandKey"] && isEmptyOtherConditions(conditions, "selectBrandKey")
}

const isStorageLocationNormalizeUrl = (conditions) => {
  return isValidDataSize(conditions, "selectStorageLocation", 1) && isEmptyOtherConditions(conditions, "selectStorageLocation")
}

const isEmptyOtherConditions = (conditions, ignoreKey) => {
  let keys = Object.keys(conditions).filter((x) => x != ignoreKey)
  let res = keys.filter((k) => {
    if (Array.isArray(conditions[k])) {
      return conditions[k].length != 0
    } else {
      switch(k) {
      case "page":
      case "sortRule":
        return false
      case "selectSoldOut":
        return conditions[k] != "select_sold_out_include"
      default:
        return conditions[k] != undefined && conditions[k] != null && conditions[k] != ""
      }
    }
  })
  return res.length == 0
}

const isValidDataSize = (conditions, key, size) => {
  if (!Array.isArray(conditions[key])) return false
  return conditions[key].length == size
}

const renderUrl = (searchFormCondition) => {
  const _searchFormCondition = JSON.parse(JSON.stringify(searchFormCondition))
  const baseUrl = '/site/items'

  switch(true) {
  case isMakerNormalizeUrl(_searchFormCondition):
    return baseUrl + "/Maker-" + _searchFormCondition.selectMaker
  case isBodyTypeNormalizeUrl(_searchFormCondition):
    return baseUrl + "/BodyKeijou-" + _searchFormCondition.selectBodyKeijou
  case isBrandNormalizeUrl(_searchFormCondition):
    return baseUrl + "/BrandKey-" + _searchFormCondition.selectBrandKey
  case isStorageLocationNormalizeUrl(_searchFormCondition):
    return baseUrl + "/StorageLocation-" + _searchFormCondition.selectStorageLocation
  default:
    return baseUrl + queryStringfy(_searchFormCondition)
  }
}

export default execSearchSubmit
