import {connect} from 'react-redux'
import {bindActionCreators } from 'redux'
import * as actions from 'actions'
import SelectBodyKeijou from 'components/site/index/select_body_keijou'

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
})

const mapStateToProps = state => ({
  systemLabelMastersApi: state.systemLabelMastersApi,
  selectBodyKeijou: state.searchFormCondition.selectBodyKeijou,
})

const SelectBodyKeijouContainer = connect(mapStateToProps, mapDispatchToProps)(SelectBodyKeijou)

export default SelectBodyKeijouContainer
