import logger from 'common/logger'
export default function getSearchItems(payload) {
  let statusCode
  logger("Api getSearchItems is called")
  return fetch('/api/v1/site/items?'+createParams(payload.searchFormCondition), {
    mode: 'same-origin',
    credentials: 'same-origin',
  })
    .then(res => {
      logger("Api getSearchItems is finished")
      logger(res)
      statusCode = res.status
      if(res.ok) {
        return res.json()
      } else {
        throw new Error(res.statusText)
      }
    })
    .then(json => ({statusCode: statusCode, body: json}))
    .catch(error => {throw new Error(error.message)})
}

const createParams = (obj) => {
  return Object.keys(obj)
    .filter((key) => {
      if (Array.isArray(obj[key])){
        return (obj[key] !== '' && obj[key].length > 0 )
      }else{
        return (obj[key] !== '' && obj[key] !== null)
      }
    })
    .map((key) => {
      if (Array.isArray(obj[key])){
        return obj[key].map((value) => {
          return key + "%5B%5D=" + encodeURIComponent(value)
        }).join("&")
      }else{
        return key + "=" + encodeURIComponent(obj[key])
      }
    })
    .concat([new Date().getTime()])  // cahce buster
    .join("&")
}
