import logger from './../common/logger'
export default function getSystemLabelMasters() {
  let statusCode
  logger("Api getSystemLabelMasters is called")
  return fetch('/api/v1/system_label_masters/', {
    mode: 'same-origin',
    credentials: 'same-origin',
  })
    .then(res => {
      logger("Api getSystemLabelMasters is finished")
      logger(res)
      statusCode = res.status
      if(res.ok) {
        return res.json()
      } else {
        throw new Error(res.statusText)
      }
    })
    .then(json => ({statusCode: statusCode, body: json}))
    .catch(error => {throw new Error(error.message)})
}
