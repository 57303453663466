import ActionTypes from 'actions/action_types'

const siteItemApi = (state =[], action) => {
  switch (action.type) {
  case ActionTypes.GET_SEARCH_ITEM:
    return {
      ...state,
      loadingFlg: true,
    };
  case ActionTypes.GET_SEARCH_ITEM_OK:
    return {
      ...state,
      payload: action.payload,
      loadingFlg: false,
    };
  default:
    return state
  }
};

export default siteItemApi
