import React, {Component} from 'react'
import SystemLabelMasterCollection from 'collections/system_label_master_collection'
import SelectMakerModal from './select_maker_modal'

class SelectMaker extends Component {
  constructor () {
    super();
    this.state = {
      showModal: false
    }
  }

  handleOpenModal = () => {
    this.setState({ showModal: true });
  }

  handleCloseModal = (e) => {
    this.setState({ showModal: false });
    e.preventDefault();
  }

  handleInputChange = (e) => {
    const value = e.target.checked
    const name = e.target.name
    this.props.actions.changeSelectMaker({
      name: name,
      value: value,
    })
  }

  /**
   * render
   */
  render () {
    const {  payload } = this.props.systemLabelMastersApi
    const selectMaker = Array(this.props.selectMaker).flat()
    if (!payload) {return null}
    const systemLabelMasterCollection = new SystemLabelMasterCollection(payload)
    return (
      <div className='searchArea__item'>
        <h3 className='searchArea__title'>{TRUCKERS.LANG.ITEM.MAKER}</h3>
        <p
          className='selectWrap searchArea__item__inner'
          onClick={this.handleOpenModal}
        >
          <span className='select'>
            {exOptionLabel(selectMaker, this.props.systemLabelMastersApi)}
          </span>
        </p>
        <SelectMakerModal
          handleCloseModal={this.handleCloseModal}
          handleInputChange={this.handleInputChange}
          selectMaker={this.props.selectMaker}
          systemLabelMasterCollection={systemLabelMasterCollection}
          appElement={document.getElementById('site_items_index_view_container')}
          state={this.state}
          {...this.props}
        />
      </div>
    )
  }
}

export const exOptionLabel = (selectMaker, systemLabelMastersApi ) => {
  const {  payload } = systemLabelMastersApi
  if (!payload || !selectMaker) {return TRUCKERS.LANG.ITEM.LIST_SEARCH.SELECT_WITH_CLICK}
  const systemLabelMasterCollection = new SystemLabelMasterCollection(payload)
  let result = []
  for (let maker of selectMaker){
    result.push(systemLabelMasterCollection.getValue('item', 'maker', maker))
  }
  return ((result.length > 0)? result.join(',') : TRUCKERS.LANG.ITEM.LIST_SEARCH.SELECT_WITH_CLICK)
}

export default SelectMaker
