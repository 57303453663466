import React from 'react'
import ReactModal from 'react-modal'
import {CloseButton, SubmitButton, CloseButtonBottom} from 'components/common/modal_parts'

const modalStyle = {
  content: {
    width: 'calc(100% - 20vw)',
    maxHeight: '90%',
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    border: "none",
    padding: "50px",
    transform: "translate(-50%, -50%)"
  },
  overlay: {
    zIndex: '150',
    backgroundColor: "rgba(19, 165, 112, 0.8)"
  },
}

/**
 * チェックボックスを描画する
 */
const InputUnit = (props) => {
  const {labelKey, checked, value, handleInputChange} = props
  return <React.Fragment>
    <input
      name={labelKey}
      id={labelKey}
      type="checkbox"
      checked={checked}
      onChange={handleInputChange}
      style={{cursor: 'pointer'}}
      className="check"
    />
    <label
      key={labelKey}
      htmlFor={labelKey}
      className='pl-1'
      style={{cursor: 'pointer'}}
    >
      {value}
    </label>
  </React.Fragment>
}

/**
 * 選択されているかチェックする
 */
const selectChecked = (selectBodyKeijou, label_key) => {
  return (selectBodyKeijou && selectBodyKeijou.indexOf(label_key) >= 0)
}

/**
 * ボディ形状を描画する
 */
const BodyKeijou = (props) => {
  const {selectBodyKeijou, systemLabelMasterCollection, labelKey} = props
  const systemLabelMasterModel = systemLabelMasterCollection.getSystemLabelMasterModel('item', 'body_keijou', labelKey)
  return <li className='modalColumn__child__item checkbox'>
    <InputUnit
      {...props}
      checked={selectChecked(selectBodyKeijou, labelKey)}
      value={systemLabelMasterModel.getValueI18n()}
      className='check'
    />
  </li>
}

/**
 * ボディ形状リストを描画する
 */
const BodyKeijouList = (props) => {
  const {bodyKeijous} = props

  let list = []
  for (let labelKey of bodyKeijous){
    list.push(
      <BodyKeijou
        {...props}
        key={labelKey}
        labelKey={labelKey}
      />
    )
  }

  return <ul className='modalColumn__child'>
    {list}
  </ul>
}

/**
 * ボディ形状グループを描画する
 */
const BodyKeijouGroup = (props) => {
  const {bodyKeijouGroup, selectBodyKeijou, systemLabelMasterCollection, bodyKeijouData} = props
  return <li className='modalColumn__item'>
    <div className='modalColumn__title'>
      <div className='checkbox'>
        <InputUnit
          {...props}
          labelKey={bodyKeijouGroup}
          checked={selectChecked(selectBodyKeijou, bodyKeijouGroup)}
          value={systemLabelMasterCollection.getValue('item', 'body_keijou_group', bodyKeijouGroup)}
        />
      </div>
    </div>
    <BodyKeijouList
      {...props}
      bodyKeijous={bodyKeijouData[bodyKeijouGroup]}
    />
  </li>
}

const SelectBodyKeijouModal = (props) => {
  const {showModal} = props.state
  const {handleCloseModal, appElement} = props
  return (
    <ReactModal
      isOpen={showModal}
      appElement={appElement}
      onRequestClose={handleCloseModal}
      style={modalStyle}
    >
      <h2 className="headline2 ReactModal__title">ボディタイプを選択</h2>
      <ul className="modalColumn">
        <BodyKeijouGroup {...props} bodyKeijouGroup='body_keijou_group_hira_body' />
        <BodyKeijouGroup {...props} bodyKeijouGroup='body_keijou_group_wing_body' />
        <BodyKeijouGroup {...props} bodyKeijouGroup='body_keijou_group_van_body' />
        <BodyKeijouGroup {...props} bodyKeijouGroup='body_keijou_group_freezer' />
        <BodyKeijouGroup {...props} bodyKeijouGroup='body_keijou_group_horo' />
        <BodyKeijouGroup {...props} bodyKeijouGroup='body_keijou_group_clane' />
        <BodyKeijouGroup {...props} bodyKeijouGroup='body_keijou_group_carrier' />
        <BodyKeijouGroup {...props} bodyKeijouGroup='body_keijou_group_tank' />
        <BodyKeijouGroup {...props} bodyKeijouGroup='body_keijou_group_dump' />
        <BodyKeijouGroup {...props} bodyKeijouGroup='body_keijou_group_mixer' />
        <BodyKeijouGroup {...props} bodyKeijouGroup='body_keijou_group_container' />
        <BodyKeijouGroup {...props} bodyKeijouGroup='body_keijou_group_packer' />
        <BodyKeijouGroup {...props} bodyKeijouGroup='body_keijou_group_aerial' />
        <BodyKeijouGroup {...props} bodyKeijouGroup='body_keijou_group_torakuta' />
        <BodyKeijouGroup {...props} bodyKeijouGroup='body_keijou_group_trailer' />
        <BodyKeijouGroup {...props} bodyKeijouGroup='body_keijou_group_tokusou' />
        <BodyKeijouGroup {...props} bodyKeijouGroup='body_keijou_group_kei' />
        <BodyKeijouGroup {...props} bodyKeijouGroup='body_keijou_group_light_van' />
        <BodyKeijouGroup {...props} bodyKeijouGroup='body_keijou_group_forklift' />
        <BodyKeijouGroup {...props} bodyKeijouGroup='body_keijou_group_bus' />
        <BodyKeijouGroup {...props} bodyKeijouGroup='body_keijou_group_juuki' />
        <BodyKeijouGroup {...props} bodyKeijouGroup='body_keijou_group_other' />
      </ul>
      <SubmitButton handleClick={handleCloseModal} />
      <CloseButton handleClick={handleCloseModal} />
      <CloseButtonBottom handleClick={handleCloseModal} />
    </ReactModal>
  )
}

export default SelectBodyKeijouModal
