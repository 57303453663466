import {connect} from 'react-redux'
import {bindActionCreators } from 'redux'
import * as actions from 'actions'
import Index from 'components/site/index'

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
})

const mapStateToProps = state => ({
  searchFormCondition: state.searchFormCondition,
  systemLabelMastersApi: state.systemLabelMastersApi,
})

const IndexContainer = connect(mapStateToProps, mapDispatchToProps)(Index)

export default IndexContainer
