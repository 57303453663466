import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import 'react-app-polyfill/ie11'

import IndexContainer from 'containers/site/index_container'
import configureStore from 'store'
import {BrowserRouter, Routes, Route} from "react-router-dom"

const store = configureStore()

if (document.getElementById('site_index_view_container') != null){
  render(
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path={'/'} element={<IndexContainer />} />
        </Routes>
      </BrowserRouter>
    </Provider>,
    document.getElementById('site_index_view_container')
  )
}
