import { call, put } from 'redux-saga/effects'
import * as actions from '../actions'
import apiGetSystemLabelMasters from '../api/get_system_label_masters'

export default function* getSystemLabelMasters() {
  try{
    const {statusCode, body} = yield call(apiGetSystemLabelMasters)
    const meta = { statusCode: statusCode }
    yield put(actions.getSystemLabelMastersOK(body, meta))
  } catch (e) {
    const payload = e.message
    yield put(actions.getSystemLabelMastersNG(payload, null))
  }
}
