import { takeEvery } from 'redux-saga/effects'
import ActionTypes from './../actions/action_types'
import getSystemLabelMasters from './get_system_label_masters'
import getSearchItems from './get_search_items'
import postFavorites from './post_favorites'
import deleteFavorites from './delete_favorites'
import getSearchItem from "./get_search_item";

export default function* rootSaga() {
  yield takeEvery(ActionTypes.GET_SYSTEM_LABEL_MASTERS, getSystemLabelMasters)
  yield takeEvery(ActionTypes.GET_SEARCH_ITEMS, getSearchItems)
  yield takeEvery(ActionTypes.POST_FAVORITES, postFavorites)
  yield takeEvery(ActionTypes.DELETE_FAVORITES, deleteFavorites)
  yield takeEvery(ActionTypes.GET_SEARCH_ITEM, getSearchItem)
}
