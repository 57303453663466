import ActionTypes from 'actions/action_types'
const siteItemsApi = (state = [], action) => {
  switch (action.type) {
  case ActionTypes.GET_SEARCH_ITEMS:
    return {
      ...state,
      loadingFlg: true,
    };
  case ActionTypes.GET_SEARCH_ITEMS_OK:
    return {
      ...state,
      payload: action.payload,
      loadingFlg: false,
    };
  case ActionTypes.GET_SEARCH_ITEMS_NG:
    return {
      ...state,
      loadingFlg: false,
    };
  case ActionTypes.POST_FAVORITES_OK:
  case ActionTypes.DELETE_FAVORITES_OK:
    return {
      ...state,
      payload: {
        ...state.payload,
        favorites: mergeFavorite(action.type, state.payload.favorites, action.payload)
      },
    };
  default:
    return state
  }
}

// post,deleteに応じて、favorite をマージする
const mergeFavorite = (actionType, favorites, favorite) => {
  if (actionType == ActionTypes.POST_FAVORITES_OK){
    return favorites.concat(favorite)
  }else{
    let result = []
    for (let row of favorites){
      if (row.id != favorite.id){
        result.push(row)
      }
    }
    return result
  }
}

export default siteItemsApi
