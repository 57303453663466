import logger from 'common/logger'
export default function postFavorites(payload) {
  let statusCode
  logger("Api postFavorites is called")
  return fetch(`/api/v1/companies/${payload.company_id}/favorites`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    mode: 'same-origin',
    credentials: 'same-origin',
    body: JSON.stringify(payload.body),
  })
    .then(res => {
      logger("Api postFavorites is finished")
      logger(res)
      statusCode = res.status
      if(res.ok) {
        return res.json()
      } else {
        throw new Error(res.statusText)
      }
    })
    .then(json => ({statusCode: statusCode, body: json}))
    .catch(error => {throw new Error(error.message)})
}
