import React, {Component} from 'react'
import SystemLabelMasterCollection from 'collections/system_label_master_collection'
import SelectBodyKeijouModal from './select_body_keijou_modal'

// define body_keijou data
export const bodyKeijouData = {
  body_keijou_group_hira_body: [
    'body_keijou_hira_body', 'body_keijou_almi_block', 'body_keijou_double_cab'
  ],
  body_keijou_group_wing_body: [ 'body_keijou_almi_wing' ],
  body_keijou_group_van_body: [ 'body_keijou_almi_van' ],
  body_keijou_group_freezer: [
    'body_keijou_reitou', 'body_keijou_reitou_wing', 'body_keijou_horei_van', 'body_keijou_horei_wing'
  ],
  body_keijou_group_horo: [
    'body_keijou_horo', 'body_keijou_horo_wing', 'body_keijou_curtain'
  ],
  body_keijou_group_clane: [
    'body_keijou_clane', 'body_keijou_truck_clane', 'body_keijou_hiab_clane'
  ],
  body_keijou_group_carrier: [
    'body_keijou_self', 'body_keijou_safty_loader', 'body_keijou_syasai_senyousya', 'body_keijou_self_clane', 'body_keijou_safty_clane', 'body_keijou_hip_lifter'
  ],
  body_keijou_group_tank: [ 'body_keijou_tank' ],
  body_keijou_group_dump: [
    'body_keijou_dump', 'body_keijou_dump_dosyakin', 'body_keijou_dump_loader', 'body_keijou_dump_lfgate'
  ],
  body_keijou_group_mixer: [ 'body_keijou_mixer' ],
  body_keijou_group_container: [ 'body_keijou_arm' ],
  body_keijou_group_packer: [
    'body_keijou_jinkai', 'body_keijou_jinkai_rotate'
  ],
  body_keijou_group_aerial: [ 'body_keijou_kousyo' ],
  body_keijou_group_torakuta: [
    'body_keijou_torakuta', 'body_keijou_torakuta_double'
  ],
  body_keijou_group_trailer: [
    'body_keijou_trailer', 'body_keijou_trailer_jyuuki', 'body_keijou_trailer_scrap', 'body_keijou_trailer_dump', 'body_keijou_trailer_pole', 'body_keijou_trailer_kaikon', 'body_keijou_trailer_kaikon_40ft', 'body_keijou_trailer_hira', 'body_keijou_trailer_wing', 'body_keijou_trailer_van', 'body_keijou_trailer_other'
  ],
  body_keijou_group_tokusou: [
    'body_keijou_tokusou_chip', 'body_keijou_tokusou_sclap', 'body_keijou_tokusou_pumping', 'body_keijou_tokusou_vacuum_dumper', 'body_keijou_chassis', 'body_keijou_tokusou_kentyuu', 'body_keijou_tokusou_vacuum', 'body_keijou_tokusou_wrecker', 'body_keijou_tokusou_kouatsusenjou', 'body_keijou_tokusou_bulk', 'body_keijou_tokusou_livestock', 'body_keijou_tokusou'
  ],
  body_keijou_group_kei: [
    'body_keijou_kei_truck', 'body_keijou_kei_van'
  ],
  body_keijou_group_light_van: [
    'body_keijou_one_box', 'body_keijou_light_van'
  ],
  body_keijou_group_forklift: [ 'body_keijou_forklift' ],
  body_keijou_group_bus: [ 'body_keijou_bus' ],
  body_keijou_group_juuki: [
    'body_keijou_juuki_rough_terrain_clane', 'body_keijou_juuki_yumbo', 'body_keijou_juuki_tire_shovel', 'body_keijou_juuki_kani_clane', 'body_keijou_juuki_shovel_loader', 'body_keijou_juuki_asphalt_finisher'
  ],
  body_keijou_group_other: [ 'body_keijou_other' ],
}

class SelectBodyKeijou extends Component {
  constructor () {
    super();
    this.state = {
      showModal: false
    }
  }

  handleOpenModal = () => {
    this.setState({ showModal: true });
  }

  handleCloseModal = (e) => {
    this.setState({ showModal: false });
    e.preventDefault();
  }

  handleInputChange = (e) => {
    const value = e.target.checked
    const name = e.target.name
    this.props.actions.changeSelectBodyKeijou({
      name: name,
      value: value,
    })
    this.includeGroupControl(name, value)
    if (value == false){
      this.upperGroupOff(name)
    }
  }

  /**
   * body_keijou_group変更時は配下のON/OFFを行う
   */
  includeGroupControl = (name, value) => {
    if (name.indexOf('body_keijou_group_') >= 0){
      for (let labelKey of bodyKeijouData[name]){
        this.props.actions.changeSelectBodyKeijou({name: labelKey, value: value})
      }
    }
  }

  /**
   * body_keijou OFF時は上位のgroupもOFFする
   */
  upperGroupOff = (name) => {
    for (let group in bodyKeijouData){
      if (bodyKeijouData[group].indexOf(name) >= 0){
        this.props.actions.changeSelectBodyKeijou({name: group, value: false})
        return
      }
    }
  }

  /**
   * render
   */
  render () {
    const {  payload } = this.props.systemLabelMastersApi
    if (!payload) {return null}
    const systemLabelMasterCollection = new SystemLabelMasterCollection(payload)
    const selectBodyKeijou = Array(this.props.selectBodyKeijou).flat()
    const systemLabelMastersApi = this.props.systemLabelMastersApi

    return (
      <div className='searchArea__item'>
        <h3 className='searchArea__title'>{TRUCKERS.LANG.ITEM.BODY_KEIJOU}</h3>
        <p
          className='searchArea__item__inner selectWrap'
          onClick={this.handleOpenModal}
        >
          <span className='select'>
            {exOptionLabel(selectBodyKeijou, systemLabelMastersApi)}
          </span>
        </p>
        <SelectBodyKeijouModal
          handleCloseModal={this.handleCloseModal}
          handleInputChange={this.handleInputChange}
          selectBodyKeijou={selectBodyKeijou}
          systemLabelMasterCollection={systemLabelMasterCollection}
          bodyKeijouData={bodyKeijouData}
          state={this.state}
          {...this.props}
        />
      </div>
    )
  }
}

export const exOptionLabel = (selectBodyKeijou, systemLabelMastersApi ) => {
  const {  payload } = systemLabelMastersApi
  if (!payload) {return TRUCKERS.LANG.ITEM.LIST_SEARCH.SELECT_WITH_CLICK}
  const systemLabelMasterCollection = new SystemLabelMasterCollection(payload)
  if (!selectBodyKeijou) {return TRUCKERS.LANG.ITEM.LIST_SEARCH.SELECT_WITH_CLICK}
  let result = []
  for (let bodyKeijou of selectBodyKeijou){
    if (bodyKeijou.indexOf('body_keijou_group_') == -1 ){
      result.push(systemLabelMasterCollection.getValue('item', 'body_keijou', bodyKeijou))
    }
  }
  return ((result.length > 0)? result.join(',') : TRUCKERS.LANG.ITEM.LIST_SEARCH.SELECT_WITH_CLICK)
}

export default SelectBodyKeijou
