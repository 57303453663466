import React from 'react'

import ReactModal from 'react-modal'
import {CloseButton, SubmitButton, CloseButtonBottom} from 'components/common/modal_parts'

const modalStyle = {
  content: {
    width: 'calc(100% - 20vw)',
    maxHeight: '90%',
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    border: "none",
    padding: "50px",
    transform: "translate(-50%, -50%)"
  },
  overlay: {
    zIndex: '150',
    backgroundColor: "rgba(19, 165, 112, 0.8)"
  },
}

/**
 * チェックボックスを描画する
 */
const InputUnit = (props) => {
  const {labelKey, checked, value, className='', handleInputChange} = props
  return (
    <div key={labelKey}  className={className}>
      <input
        name={labelKey}
        id={labelKey}
        type="checkbox"
        checked={checked}
        onChange={handleInputChange}
        style={{cursor: 'pointer'}}
        className="check"
      />
      <label
        key={labelKey}
        htmlFor={labelKey}
        className='pl-1'
        style={{cursor: 'pointer'}}
      >
        {value}
      </label>
    </div>
  )
}

/**
 * 選択されているかチェックする
 */
const selectChecked = (selectStorageLocation, label_key) => {
  if (selectStorageLocation && selectStorageLocation.indexOf(label_key) >= 0){
    return true
  }
  return false
}

/**
 * 県を描画する
 */
const Pref = (props) => {
  const {selectStorageLocation, systemLabelMasterCollection, labelKey} = props
  const systemLabelMasterModel = systemLabelMasterCollection.getSystemLabelMasterModel('storage_location', 'todoufuken', labelKey)
  return(
    <InputUnit
      {...props}
      checked={selectChecked(selectStorageLocation, labelKey)}
      value={systemLabelMasterModel.getValueI18n()}
      className='regionList__item__prof checkbox'
    />
  )
}

/**
 * 県のリストを描画する
 */
const Prefs = (props) => {
  const {prefs, noPref} = props

  if (noPref){  // prefがない（北海道など）
    return null
  }

  let list = []
  for (let labelKey of prefs){
    list.push(
      <Pref
        {...props}
        key={labelKey}
        labelKey={labelKey}
      />
    )
  }

  return (
    <div key={prefs[0]}>
      {list}
    </div>
  )
}

/**
 * 地域を描画する
 */
const Region = (props) => {
  const {regionName, selectStorageLocation, systemLabelMasterCollection, prefsData} = props
  return (
    <li className="regionList__item">
      <InputUnit
        {...props}
        labelKey={regionName}
        checked={selectChecked(selectStorageLocation, regionName)}
        value={systemLabelMasterCollection.getValue('storage_location', 'region', regionName)}
        className='regionList__item__title checkbox'
      />
      <Prefs
        {...props}
        prefs={prefsData[regionName]}
      />
    </li>
  )
}

const SelectStorageLocationModal = (props) => {
  const {showModal} = props.state
  const {handleCloseModal  } = props
  return (
    <ReactModal
      isOpen={showModal}
      contentLabel="Inline Styles Modal Example"
      appElement={document.getElementById('site_items_index_view_container')}
      onRequestClose={handleCloseModal}
      style={modalStyle}
    >
      <h2 className="headline2 ReactModal__title">{TRUCKERS.LANG.ITEM.LIST_SEARCH.SELECT_REGION}</h2>
      <ul className="regionList">
        <Region {...props} regionName='region_hokkaidou' noPref={true} />
        <Region {...props} regionName='region_touhoku' />
        <Region {...props} regionName='region_kantou' />
        <Region {...props} regionName='region_hokuriku' />
        <Region {...props} regionName='region_toukai' />
        <Region {...props} regionName='region_kinki' />
        <Region {...props} regionName='region_chugoku' />
        <Region {...props} regionName='region_shikoku' />
        <Region {...props} regionName='region_kyuusyuu_hokubu' />
        <Region {...props} regionName='region_kyuusyuu_nanbu' />
      </ul>
      <SubmitButton handleClick={handleCloseModal} />
      <CloseButton handleClick={handleCloseModal} />
      <CloseButtonBottom handleClick={handleCloseModal} />
    </ReactModal>
  )
}

export default SelectStorageLocationModal
