import { call, put } from 'redux-saga/effects'
import * as actions from '../actions'
import apiGetSearchItem from "../api/get_search_item";

export default function* getSearchItem(action) {
  try {
    const { statusCode, body } = yield call(apiGetSearchItem, action.payload);
    const meta = { statusCode: statusCode };
    yield put(actions.getSearchItemOK(body, meta))
  } catch (e) {
    e
  }
}
