import ActionTypes from './../actions/action_types'
const systemLabelMastersApi = (state = [], action) => {
  switch (action.type) {
  case ActionTypes.GET_SYSTEM_LABEL_MASTERS:
    return {
      ...state,
      loadingFlg: true,
    };
  case ActionTypes.GET_SYSTEM_LABEL_MASTERS_OK:
    return {
      ...state,
      payload: action.payload,
      loadingFlg: false,
    };
  case ActionTypes.GET_SYSTEM_LABEL_MASTERS_NG:
    return {
      ...state,
      loadingFlg: false,
    };
  default:
    return state
  }
}

export default systemLabelMastersApi
