import BaseModel from './base_model'
export default class SystemLabelMasterModel extends BaseModel {
  attributes = {}
  constructor(record) {
    super()
    this.attributes = record
  }

  /**
   * I18nlocaleでラベル値を取得する
   */
  getValueI18n() {
    let locale = ''
    if (TRUCKERS.PARAMS.I18N_LOCALE){
      locale = locale + TRUCKERS.PARAMS.I18N_LOCALE
    }else{
      locale = locale + 'ja'
    }
    return this.attributes["value_"+locale]
  }
}
