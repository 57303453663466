import { call, put } from 'redux-saga/effects'
import * as actions from '../actions'
import apiGetSearchItems from '../api/get_search_items'

export default function* getSearchItems(action) {
  try{
    const {statusCode, body} = yield call(apiGetSearchItems, action.payload)
    const meta = { statusCode: statusCode }
    yield put(actions.getSearchItemsOK(body, meta))
  } catch (e) {
    const payload = e.message
    yield put(actions.getSearchItemsNG(payload, null))
  }
}
