import {connect} from 'react-redux'
import {bindActionCreators } from 'redux'
import * as actions from 'actions'
import SelectMaker from 'components/site/index/select_maker'

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
})

const mapStateToProps = state => ({
  systemLabelMastersApi: state.systemLabelMastersApi,
  selectMaker: state.searchFormCondition.selectMaker,
})

const SelectMakerContainer = connect(mapStateToProps, mapDispatchToProps)(SelectMaker)

export default SelectMakerContainer
