import React, {Component} from 'react'
import SystemLabelMasterCollection from 'collections/system_label_master_collection'

class SelectBodySize extends Component {

  handleChange = (e) => {
    this.props.actions.changeSelectBodySize({
      selectBodySize: e.target.value,
    })
  }

  render() {
    const {  payload } = this.props.systemLabelMastersApi
    if (!payload) {return null}
    const systemLabelMasterCollection = new SystemLabelMasterCollection(payload)
    return (
      <li className='topSearchForm__item'>
        <h3 className='topSearchForm__title'>{TRUCKERS.LANG.ITEM.BODY_SIZE}</h3>
        <p className="selectWrap">
          <select className="select"
            onChange={this.handleChange}
          >
            <option value="" key="all">すべて</option>
            {
              systemLabelMasterCollection.getValueList('item','body_size').map(i => (
                <option value={i.get('label_key')} key={i.get('label_key')}>{i.getValueI18n()}</option>
              ))
            }
          </select>
        </p>
      </li>
    )
  }
}
export default SelectBodySize
