import ActionTypes from 'actions/action_types'
import logger from 'common/logger'
import { mergeState } from './common'
import { queryStringParse } from 'common/util'
import { prefsData } from '../components/site/select_storage_location'

const initialState = {
  selectBodyKeijou: [],
  selectMaker: [],
  selectBodySize: '',
  selectTransmission: '',
  selectBed: '',
  selectKudouhoushiki: '',
  selectFuelType: '',
  selectPowerGate: '',
  selectNenshikiStart: '',
  selectNenshikiFinish: '',
  selectRunningDistanceStart: '',
  selectRunningDistanceFinish: '',
  selectPriceStart: '',
  selectPriceFinish: '',
  selectStorageLocation: [],
  selectSellerKind: '',
  selectSoldOut: 'select_sold_out_include',
  selectExhibitType: '',
  selectFreeWord: '',
  selectEngineBariki: '',
  selectBrandKey: '',
  selectSaidaisekisairyouStart: '',
  selectSaidaisekisairyouFinish: '',
  selectSyaryousoujuuryouStart: '',
  selectSyaryousoujuuryouFinish: '',
  selectCabinWidth: '',
  selectSuspension: '',
  selectNishitsunaisunLengthStart: '',
  selectNishitsunaisunLengthFinish: '',
  selectNishitsunaisunWidthStart: '',
  selectNishitsunaisunWidthFinish: '',
  selectNishitsunaisunHeightStart: '',
  selectNishitsunaisunHeightFinish: '',
  selectSyaryougaisunLengthStart: '',
  selectSyaryougaisunLengthFinish: '',
  selectSyaryougaisunWidthStart: '',
  selectSyaryougaisunWidthFinish: '',
  selectSyaryougaisunHeightStart: '',
  selectSyaryougaisunHeightFinish: '',
  execGetSearchItems: false,
  id_list: '',
};

const getMergedQueryParamsState = () => {
  let state = { ...initialState };
  const params= queryStringParse(location.search.substr(1));
  for (let [k, v] of Object.entries(params)) {
    if (state.hasOwnProperty(k)) {
      state[k] = v;
    }
  }
  return state;
};

const getMergedPathnameState = (initialState) => {
  let state = { ...initialState };
  let matches = null;

  // NOTE: brandの処理を入れると、何も条件を選択していなくてもbrandに飛び続けるのでbrandをいれるときはフォームの対応も必要
  if(matches = location.pathname.match(/^\/site\/items\/BodyKeijou-(.+)/)) {
    state['selectBodyKeijou'].push(matches[1]);
  } else if (matches = location.pathname.match(/^\/site\/items\/Maker-(.+)/)) {
    state['selectMaker'].push(matches[1]);
  } else if (matches = location.pathname.match(/^\/site\/items\/StorageLocation-(.+)/)) {
    state['selectStorageLocation'].push(matches[1]);
    const regionToPrefs = prefsData[matches[1]];
    if (regionToPrefs) {
      const ary = state['selectStorageLocation'].concat(regionToPrefs);
      state['selectStorageLocation'] = ary.filter((a, i) => ary.indexOf(a) == i);
    }
  } else if (matches = location.pathname.match(/^\/site\/items\/BodySize-(.+)/)) {
    state['selectBodySize'] = matches[1];
  }
  return state;
}

const searchFormCondition = (state = initialState, action) => {
  logger('====> Reducer searchFormCondition is called.')
  logger('state is')
  logger(state)
  logger('action is')
  logger(action)
  switch (action.type) {
  case ActionTypes.GET_SEARCH_ITEMS:
    return {
      ...state,
    }
  case ActionTypes.CHANGE_SELECT_BODY_KEIJOU:
    return {
      ...state,
      selectBodyKeijou: mergeState(state, action, 'selectBodyKeijou'),
    }
  case ActionTypes.CHANGE_SELECT_MAKER:
    return {
      ...state,
      selectMaker: mergeState(state, action, 'selectMaker'),
    }
  case ActionTypes.CHANGE_SELECT_BODY_SIZE:
    return {
      ...state,
      selectBodySize: action.payload.selectBodySize,
    }
  case ActionTypes.CHANGE_SELECT_TRANSMISSION:
    return {
      ...state,
      selectTransmission: action.payload.selectTransmission,
    }
  case ActionTypes.CHANGE_SELECT_BED:
    return {
      ...state,
      selectBed: action.payload.selectBed,
    }
  case ActionTypes.CHANGE_SELECT_KUDOUHOUSHIKI:
    return {
      ...state,
      selectKudouhoushiki: action.payload.selectKudouhoushiki,
    }
  case ActionTypes.CHANGE_SELECT_FUEL_TYPE:
    return {
      ...state,
      selectFuelType: action.payload.selectFuelType,
    }
  case ActionTypes.CHANGE_SELECT_POWER_GATE:
    return {
      ...state,
      selectPowerGate: action.payload.selectPowerGate,
    }
  case ActionTypes.CHANGE_SELECT_NENSHIKI_START:
    return {
      ...state,
      selectNenshikiStart: action.payload.selectNenshikiStart,
    }
  case ActionTypes.CHANGE_SELECT_NENSHIKI_FINISH:
    return {
      ...state,
      selectNenshikiFinish: action.payload.selectNenshikiFinish,
    }
  case ActionTypes.CHANGE_SELECT_RUNNING_DISTANCE_START:
    return {
      ...state,
      selectRunningDistanceStart: action.payload.selectRunningDistanceStart,
    }
  case ActionTypes.CHANGE_SELECT_RUNNING_DISTANCE_FINISH:
    return {
      ...state,
      selectRunningDistanceFinish: action.payload.selectRunningDistanceFinish,
    }
  case ActionTypes.CHANGE_SELECT_PRICE_START:
    return {
      ...state,
      selectPriceStart: action.payload.selectPriceStart,
    }
  case ActionTypes.CHANGE_SELECT_PRICE_FINISH:
    return {
      ...state,
      selectPriceFinish: action.payload.selectPriceFinish,
    }
  case ActionTypes.CHANGE_SELECT_STORAGE_LOCATION:
    return {
      ...state,
      selectStorageLocation: mergeState(state, action, 'selectStorageLocation')
    }
  case ActionTypes.CHANGE_SELECT_SELLER_KIND:
    return {
      ...state,
      selectSellerKind: action.payload.selectSellerKind,
    }
  case ActionTypes.CHANGE_SELECT_SOLD_OUT:
    return {
      ...state,
      selectSoldOut: action.payload.selectSoldOut,
    }
  case ActionTypes.CHANGE_SELECT_BRAND_KEY:
    return {
      ...state,
      selectBrandKey: action.payload.selectBrandKey,
    }
  case ActionTypes.CHANGE_SELECT_FREE_WORD:
    return {
      ...state,
      selectFreeWord: action.payload.selectFreeWord,
    }
  case ActionTypes.CHANGE_SELECT_ENGINE_BARIKI:
    return {
      ...state,
      selectEngineBariki: action.payload.selectEngineBariki,
    }
  case ActionTypes.CHANGE_SELECT_EXHIBIT_TYPE:
    return {
      ...state,
      selectExhibitType: action.payload.selectExhibitType
    };
  case ActionTypes.CLEAR_CONDITION:
    return {
      ...initialState,
      selectExhibitType: state.selectExhibitType,
    }
  case ActionTypes.CHANGE_PAGE:
    return {
      ...state,
      page: action.payload.page,
    }
  case ActionTypes.EXEC_GET_SEARCH_ITEMS:
    return {
      ...state,
      execGetSearchItems: true,
    }
  case ActionTypes.EXEC_GET_SEARCH_ITEMS_COMPLETE:
    return {
      ...state,
      execGetSearchItems: false,
    }
  case ActionTypes.SETUP_SEARCH_FORM_CONDITION:
    return getMergedPathnameState(getMergedQueryParamsState());
  case ActionTypes.CHANGE_SORT_RULE:
    return {
      ...state,
      sortRule: action.payload.sortRule,
    };
  case ActionTypes.CHANGE_SELECT_SAIDAISEKISAIRYOU_START:
    return {
      ...state,
      selectSaidaisekisairyouStart: action.payload.selectSaidaisekisairyouStart,
    }
  case ActionTypes.CHANGE_SELECT_SAIDAISEKISAIRYOU_FINISH:
    return {
      ...state,
      selectSaidaisekisairyouFinish: action.payload.selectSaidaisekisairyouFinish,
    }
  case ActionTypes.CHANGE_SELECT_SYARYOUSOUJUURYOU_START:
    return {
      ...state,
      selectSyaryousoujuuryouStart: action.payload.selectSyaryousoujuuryouStart,
    }
  case ActionTypes.CHANGE_SELECT_SYARYOUSOUJUURYOU_FINISH:
    return {
      ...state,
      selectSyaryousoujuuryouFinish: action.payload.selectSyaryousoujuuryouFinish,
    }
  case ActionTypes.CHANGE_SELECT_CABIN_WIDTH:
    return {
      ...state,
      selectCabinWidth: action.payload.selectCabinWidth,
    }
  case ActionTypes.CHANGE_SELECT_SUSPENSION:
    return {
      ...state,
      selectSuspension: action.payload.selectSuspension,
    }
  case ActionTypes.CHANGE_SELECT_NISHITSUNAISUN_LENGTH_START:
    return {
      ...state,
      selectNishitsunaisunLengthStart: action.payload.selectNishitsunaisunLengthStart,
    }
  case ActionTypes.CHANGE_SELECT_NISHITSUNAISUN_LENGTH_FINISH:
    return {
      ...state,
      selectNishitsunaisunLengthFinish: action.payload.selectNishitsunaisunLengthFinish,
    }
  case ActionTypes.CHANGE_SELECT_NISHITSUNAISUN_WIDTH_START:
    return {
      ...state,
      selectNishitsunaisunWidthStart: action.payload.selectNishitsunaisunWidthStart,
    }
  case ActionTypes.CHANGE_SELECT_NISHITSUNAISUN_WIDTH_FINISH:
    return {
      ...state,
      selectNishitsunaisunWidthFinish: action.payload.selectNishitsunaisunWidthFinish,
    }
  case ActionTypes.CHANGE_SELECT_NISHITSUNAISUN_HEIGHT_START:
    return {
      ...state,
      selectNishitsunaisunHeightStart: action.payload.selectNishitsunaisunHeightStart,
    }
  case ActionTypes.CHANGE_SELECT_NISHITSUNAISUN_HEIGHT_FINISH:
    return {
      ...state,
      selectNishitsunaisunHeightFinish: action.payload.selectNishitsunaisunHeightFinish,
    }
  case ActionTypes.CHANGE_SELECT_SYARYOUGAISUN_LENGTH_START:
    return {
      ...state,
      selectSyaryougaisunLengthStart: action.payload.selectSyaryougaisunLengthStart,
    }
  case ActionTypes.CHANGE_SELECT_SYARYOUGAISUN_LENGTH_FINISH:
    return {
      ...state,
      selectSyaryougaisunLengthFinish: action.payload.selectSyaryougaisunLengthFinish,
    }
  case ActionTypes.CHANGE_SELECT_SYARYOUGAISUN_WIDTH_START:
    return {
      ...state,
      selectSyaryougaisunWidthStart: action.payload.selectSyaryougaisunWidthStart,
    }
  case ActionTypes.CHANGE_SELECT_SYARYOUGAISUN_WIDTH_FINISH:
    return {
      ...state,
      selectSyaryougaisunWidthFinish: action.payload.selectSyaryougaisunWidthFinish,
    }
  case ActionTypes.CHANGE_SELECT_SYARYOUGAISUN_HEIGHT_START:
    return {
      ...state,
      selectSyaryougaisunHeightStart: action.payload.selectSyaryougaisunHeightStart,
    }
  case ActionTypes.CHANGE_SELECT_SYARYOUGAISUN_HEIGHT_FINISH:
    return {
      ...state,
      selectSyaryougaisunHeightFinish: action.payload.selectSyaryougaisunHeightFinish,
    }
  default:
    return {
      ...state,
    }
  }
}

export default searchFormCondition
