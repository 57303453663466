import {connect} from 'react-redux'
import {bindActionCreators } from 'redux'
import * as actions from 'actions'
import SelectRunningDistance from 'components/site/index/select_running_distance'

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
})

const mapStateToProps = state => ({
  systemLabelMastersApi: state.systemLabelMastersApi,
  selectRunningDistanceFinish: state.searchFormCondition.selectRunningDistanceFinish,
})

const SelectRunningDistanceContainer = connect(mapStateToProps, mapDispatchToProps)(SelectRunningDistance)

export default SelectRunningDistanceContainer
