import { combineReducers } from 'redux'
import systemLabelMastersApi from './system_label_masters_api'
import searchFormCondition from './search_form_condition'
import siteItemsApi from './site_items_api'
import siteItemApi from './site_item_api'

export default combineReducers({
  systemLabelMastersApi,
  searchFormCondition,
  siteItemsApi,
  siteItemApi,
})
